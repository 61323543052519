import React, {useRef, useState} from 'react';
import * as img from '../images/header.png'
import * as bitcoin from '../images/bitcoin.svg'
import * as arrowDown from '../images/arrow-down.svg'
import * as burger from '../images/burger.svg'
import exitImg from '../images/rejected-for-route-item.svg'
import SmallCurrency from "./SmallCurrency";
import gif from '../images/gif.gif'
import * as events from "events";
import * as ken from '../images/kenshoman-removebg.png';
import * as img_b from "../images/light_h.png";
import * as sun from "../images/sun.svg";
import * as sun_b from "../images/sun_b.svg";
const FirstFrame = ({width, darkMode, toggleTheme}) => {

    const [isBurgerMenuOpened, setIsBurgerMenuOpened] = useState(false)
    const burgerRef = useRef(null)

    const [thorchain, setTokens] = React.useState([])
    const coins_ids = [ 'bitcoin' , 'ethereum', 'avalanche-2', 'litecoin', 'cosmos', 'dogecoin', 'thorchain', 'bitcoin-cash', 'chainlink', 'binancecoin', 'polkadot', 'optimism', 'solana', 'matic-network', 'crypto-com-chain']
    React.useEffect(() => {
        fetch('https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc')
            .then(response => response.json())
            .then(data => {
                const roundedData = data.map(token => ({
                    ...token,
                    current_price: Math.round(token.current_price * 100) / 100
                }));
                setTokens(roundedData);
            })
    }, [])


    // find coins ids in thorchain
    const coins = thorchain.filter((item) => {
        return coins_ids.includes(item.id)
    })

    const FirstFrame = document.querySelector(`.App${darkMode ? '-dark' : ''}`)
    const landingGif = document.querySelector('.landing-gif')
    if (landingGif && FirstFrame) {
        FirstFrame.addEventListener('mousemove', (event) => {
            const x = event.clientX / window.innerWidth
            const y = event.clientY / window.innerHeight
            landingGif.style.transform = 'translate(-' + x * 30 + 'px, -' + y * 30 + 'px)'
        })
    }
    // <div className={`burgerMenu${darkMode ? '-dark' : ''}`} ref={burgerRef}>

    return (
        <div className={`landing-first-frame${darkMode ? '-dark' : ''}`}>

            {
                isBurgerMenuOpened ?
                    <div className={`burgerMenu${darkMode ? '-dark' : ''}`} ref={burgerRef}>
                        <img src={exitImg} alt="" className='exit-burger-menu'
                        onClick={() => {
                            setIsBurgerMenuOpened(false)
                            document.body.style.overflow = 'scroll'
                            window.onclick = (event) => {}
                        }}/>
                        <img className='darkmode' onClick={toggleTheme} src={darkMode ? sun_b.default : sun.default}
                            alt=""
                            style={{
                                width: '30px',
                                height: '30px',
                                cursor: 'pointer',
                            }}
                        ></img>
                        <div
                            onClick={() => { window.open('https://kensho-finance.gitbook.io/kensho-docs/') }}
                            style={{cursor: 'pointer'}}
                         >Docs</div>
                        <div
                         onClick={() => { window.open('https://kensho-finance.gitbook.io/kensho-docs/products') }}
                         style={{cursor: 'pointer'}}
                        >Products</div>
                        <div
                         onClick={() => { window.open('https://kensho-finance.gitbook.io/kensho-docs/products') }}
                         style={{cursor: 'pointer'}}
                        >App</div>
                    </div> : null
            }
            <div className={`landing-header`}>
                <img className="landing-header-img" src={img.default} alt=""/>
                {
                    width >= 1200 ?
                        <div className='landing-header-flex2'>
                            
                            <img className='darkmode' onClick={toggleTheme} src={darkMode ? sun_b.default : sun.default}
                            alt=""
                            style={{
                                width: '30px',
                                height: '30px',
                                cursor: 'pointer',
                            }}
                            ></img>
                            <div className={`landing-header-button${darkMode ? '-dark' : ''}`}
                            onClick={() => { window.open('https://kensho-finance.gitbook.io/kensho-docs/') }}>Docs</div>
                            <div className={`landing-header-button${darkMode ? '-dark' : ''}`}
                            onClick={() => { window.open('https://kensho-finance.gitbook.io/kensho-docs/products') }}>Products</div>
                            <div className={`landing-header-button2${darkMode ? '-dark' : ''}`}>
                                <div className={`landing-header-button2-inner${darkMode ? '-dark' : ''}`}>
                                    <div
                                         onClick={() => { window.open('http://app.kensho.finance/swap') }}
                                    >
                                        Go to Kensho app
                                    </div>
                                </div>
                            </div>
                        </div> :
                        <img src={burger.default} alt="" className='burger'
                        onClick={() => {
                            setIsBurgerMenuOpened(true)
                            document.body.style.overflow = 'hidden'
                            setTimeout(() => {
                                window.onclick = (event) => {
                                    if(!event.composedPath().includes(burgerRef.current)) {
                                        setIsBurgerMenuOpened(false)
                                        document.body.style.overflow = 'scroll'
                                        window.onclick = (event) => {}
                                    }
                                }
                            }, 100)
                        }}/>
                }

            </div>
            <div className='landing-w'>
            <div className='scaling-wrapper'>
            <div className='landing-first-frame-grid-text'>
                {/* <div className='landing-first-frame-main-text'> */}
                <div className={`landing-first-frame-main-text${darkMode ? '-dark' : ''}`}>
                    <div>Kensho</div>
                    <div>Finance</div>
                </div>
                {
                    width < 1200 ?
                        <img className='landing-gif' src={darkMode ? ken.default : img_b.default} alt="" style={{
                            position: 'unset'
                        }}/> : null
                }
                <div className={`landing-first-frame-small-text${darkMode ? '-dark' : ''}`}>
                    Kensho Finance is a decentralized trading protocol that provides users with access to <b>9+
                    blockchains at 0% fees. </b>
                    Pick digital assets and trade from your own wallet with a <b>high degree of security.</b>
                </div>
            </div>
            <div className={`landing-first-frame-button${darkMode ? '-dark' : ''}`}
                onClick={() => { window.open('https://app.kensho.finance/swap') }}
            >
                Go to Kensho app
            </div>
            {
                width >= 1200 ?
                    <img className='landing-gif' src={darkMode ? ken.default : img_b.default} alt=""/> : null
            }
            </div>
            </div>
            {/* <div className='landing-small-currencies'> */}
            < div className={`landing-small-currencies${darkMode ? '-dark' : ''}`}>
            {
                    coins.map((thorchain) => {
                        return (
                            <SmallCurrency img={thorchain.image} number={thorchain.current_price} text={thorchain.symbol} annualPercentageRate={thorchain.price_change_percentage_24h} darkMode={darkMode}/>
                        )
                    })
                }
            </div>

            <div className={`landing-scroll-button${darkMode ? '-dark' : ''}`}>
                <img src={arrowDown.default} alt=""/>
                <div>Scroll</div>
            </div>

        </div>
    );
};

export default FirstFrame;