import React from 'react';


const ComponentOfEighthFrame = ({text, img, link, darkMode }) => {
    // make the ifGradient true when user hovers over the component and make it false when user leaves the component
    const [ifGradient, setIfGradient] = React.useState(false);
    console.log('darkMode8: ', darkMode)
    return (
        <div onMouseEnter={() => setIfGradient(true)} 
             onMouseLeave={() => setIfGradient(false)}
             className={`landing-eighth-frame${darkMode ? '-dark' : ''}-componentOfEighthFrame`}
             onClick={() => { if (link) window.open(link) }}
        >
            <div className={ifGradient ? `landing-eighth-frame${darkMode ? '-dark' : ''}-componentOfEighthFrame-gradient` : ''}>{text}</div>
            <img 
            src={img} alt=""/>
        </div>
    );

    // return (
    //     <div className='landing-eighth-frame-componentOfEighthFrame' onClick={() => { if (link) window.open(link) }}>
    //         <div className={ifGradient ? 'landing-eighth-frame-componentOfEighthFrame-gradient' : ''}>{text}</div>
    //         <img src={img} alt=""/>
    //     </div>
    // );
};

export default ComponentOfEighthFrame;