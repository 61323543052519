import React from 'react';
import './small-currency.css'

const SmallCurrency = ({img, number, text, annualPercentageRate, darkMode}) => {
    let color = '#FFFFFF'
    if (annualPercentageRate > 0) {
        color = '#1AE43A'
    } else if (annualPercentageRate < 0) {
        color = '#FF0000'
    }
    return (
        <div className={`landing-small-currency${darkMode ? '-dark' : ''}`}>
            <img className='landing-small-currency-img' src={img} alt=""/>
            <div className='landing-small-currency-img-number'
            style={{
                    color: color
                }}
            >{number}</div>
            <div className='landing-small-currency-img-text'>{text}</div>
        </div>
    );
};

export default SmallCurrency;