import React from 'react';

const Footer = () => {
    return (
        <div className='landing-footer'>
            <div>© 2024 Kensho Finance</div >
            <div>
                <a className='tos-link' href="https://kensho-finance.gitbook.io/kensho-docs/terms-of-service" rel="noopener noreferrer">
                    Terms of Service
                </a>
            </div>
        </div>
    );
};

export default Footer;