import React from 'react';


{/* <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" viewBox="0 0 55 55" fill="none">
<path d="M23.0908 25.2083C24.4658 25.2083 25.5796 26.2396 25.5544 27.5C25.5544 28.7604 24.4681 29.7916 23.0908 29.7916C21.7388 29.7916 20.625 28.7604 20.625 27.5C20.625 26.2396 21.7135 25.2083 23.0908 25.2083ZM31.9092 25.2083C33.2865 25.2083 34.375 26.2396 34.375 27.5C34.375 28.7604 33.2865 29.7916 31.9092 29.7916C30.5571 29.7916 29.4456 28.7604 29.4456 27.5C29.4456 26.2396 30.5319 25.2083 31.9092 25.2083ZM43.2919 4.58331C45.9571 4.58331 48.125 6.79706 48.125 9.54019V52.7083L43.0581 48.1364L40.205 45.4414L37.1869 42.5769L38.4381 47.0296H11.7081C9.04292 47.0296 6.875 44.8158 6.875 42.0727V9.54019C6.875 6.79706 9.04292 4.58331 11.7081 4.58331H43.2896H43.2919ZM34.194 36.0089C39.4029 35.8416 41.4081 32.3514 41.4081 32.3514C41.4081 24.6033 38.0119 18.3219 38.0119 18.3219C34.6202 15.7254 31.389 15.7964 31.389 15.7964L31.059 16.1814C35.0671 17.4327 36.9279 19.2385 36.9279 19.2385C34.7395 18.0056 32.3277 17.2203 29.8329 16.9285C28.2504 16.7498 26.6519 16.7652 25.0731 16.9744C24.931 16.9744 24.8119 16.9996 24.6721 17.0225C23.8471 17.0958 21.8419 17.4075 19.321 18.5396C18.4502 18.9475 17.93 19.2385 17.93 19.2385C17.93 19.2385 19.8871 17.3364 24.129 16.0852L23.8929 15.7964C23.8929 15.7964 20.664 15.7254 17.27 18.3241C17.27 18.3241 13.876 24.6033 13.876 32.3514C13.876 32.3514 15.856 35.8394 21.065 36.0089C21.065 36.0089 21.9358 34.9273 22.6462 34.0129C19.651 33.0962 18.5213 31.1712 18.5213 31.1712C18.5213 31.1712 18.755 31.3408 19.179 31.5814C19.2019 31.6044 19.2248 31.6296 19.2729 31.6525C19.344 31.7029 19.415 31.7258 19.486 31.7739C20.075 32.1108 20.664 32.3744 21.2048 32.5921C22.1719 32.9771 23.3269 33.3621 24.6721 33.6279C26.6872 34.0229 28.7591 34.0307 30.7771 33.6508C31.9525 33.441 33.0993 33.0941 34.194 32.6173C35.019 32.3033 35.9379 31.845 36.905 31.1964C36.905 31.1964 35.7271 33.1696 32.6379 34.061C33.346 34.9754 34.1963 36.0089 34.1963 36.0089H34.194Z" fill="url(#paint0_linear_302_32)"/>
<defs>
<linearGradient id="paint0_linear_302_32" x1="7.76346" y1="4.58335" x2="69.339" y2="7.52652" gradientUnits="userSpaceOnUse">
<stop stop-color="#CE1D9C"/>
<stop offset="1" stop-color="#AB1DCE"/>
</linearGradient>
</defs>
</svg> */}

const Discord = ({gradient, path}) => {
    // #150F17 - black
    // #CE1D9C - pink gradient
    // gradient - true or false (if true, it will use the pink)
    const black = "#150F17";
    const pink = "#CE1D9C";
    const purple = "#AB1DCE";
    const color = gradient ? pink : black;
    const purpleColor = gradient ? purple : black;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="49" viewBox="0 0 43 49" fill="none">
            <path d={path} fill="url(#paint0_linear_302_97)"/>
            <defs>
                <linearGradient id="paint0_linear_302_97" x1="1.76346" y1="0.583284" x2="63.339" y2="3.52646" gradientUnits="userSpaceOnUse">
                    <stop stop-color={gradient}/>
                    <stop offset="1" stop-color={purpleColor}/>
                </linearGradient>
            </defs>
        </svg>
    );
};

export default Discord;

// background: linear-gradient(0deg, #150F17, #150F17),
// linear-gradient(93.19deg, #CE1D9C 2.04%, #AB1DCE 143.75%);
