import './App.css';
import FirstFrame from "./components/FirstFrame";
import SecondFrame from "./components/SecondFrame";
import './scss/index.scss'
import ThirdFrame from "./components/ThirdFrame";
import FourthFrame from "./components/FourthFrame";
import FifthFrame from "./components/FifthFrame";
import SixthFrame from "./components/SixthFrame";

import EighthFrame from "./components/EighthFrame";
import Footer from "./components/Footer";
import {useEffect, useState, useRef} from "react";
import * as img from './images/header.png'
import * as burger from './images/burger.svg'
import exitImg from './images/rejected-for-route-item.svg'
function App() {
    
    const [isBurgerMenuOpened, setIsBurgerMenuOpened] = useState(false)
    const burgerRef = useRef(null)
    const [width, setWidth] = useState(0)
    useEffect(() => {
        setWidth(document.body.offsetWidth)
        window.onresize = () => {
            setWidth(document.body.offsetWidth)
        }
    }, [])

    // dark mode
    const [isDarkMode, setIsDarkMode] = useState(true)
    const toggleTheme = () => {
        setIsDarkMode(!isDarkMode)
    }


    return (
        <div className={`App${isDarkMode ? '-dark' : ''}`}>
            <FirstFrame width={width} darkMode = {isDarkMode} toggleTheme={toggleTheme}/>
            <EighthFrame darkMode = {isDarkMode}/>
            <Footer/>
        </div>
    );
}

export default App;