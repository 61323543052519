import React from 'react';
import ComponentOfEighthFrame from "./ComponentOfEighthFrame";
import thorchain from "../images/THORCHAIN.png";
import lifi from "../images/LIFI.png";
import nufi from "../images/NUFI.png";
import magpie from "../images/maya.png";
import thorchainwh from "../images/THORCHAINwh.png";
import lifwh from "../images/LIFIwh.png";
import nufiwh from "../images/NUFIwh.png";
import magpiewh from "../images/MAGPIEwh.png";
import img1 from '../images/eighth-frame1.svg'
import img2 from '../images/eighth-frame2.svg'
import img3 from '../images/eighth-frame3.svg'
import img4 from '../images/eighth-frame4.svg'
import img5 from '../images/eighth-frame5.svg'
import img6 from '../images/eighth-frame6.svg'
import img7 from '../images/eighth-frame7.svg'
import * as gradImg from "../images/gradient-sign2.svg";

import Discord from "./Small";

const EighthFrame = ({darkMode}) => {
    console.log('darkMode88: ', darkMode)
    return (
        <div className={`landing-eighth-frame${darkMode ? '-dark' : ''}`}>
            <div className={`landing-second-frame-main-text${darkMode ? '-dark' : ''}`}>
                <div/>
                <div >Links</div>
            </div>
            <div className={`landing-first-frame-small-text${darkMode ? '-dark' : ''} landing-fourth-frame${darkMode ? '-dark' : ''}-default-text`}
                style={{ 
                    color: darkMode ? '#FCFCFC' : '#150F17',
                }} 
            >
                We want our users to have a say in how the app is run and what features are added.
                Join the Kensho community and learn more or ask some questions.
            </div>
            <div className={`landing-eighth-frame${darkMode ? '-dark' : ''}-flex`}>
                <ComponentOfEighthFrame
                    text='Documentation' 
                    img={img1} 
                    link='https://kensho-finance.gitbook.io/kensho-docs'
                    darkMode={darkMode}
                />
                <ComponentOfEighthFrame darkMode={darkMode} text='Blog' img={img2} link='https://medium.com/@decentralizedkenshofinance'/>
                <ComponentOfEighthFrame darkMode={darkMode} text='Discord' img={img3}/>
                <ComponentOfEighthFrame darkMode={darkMode} text='Twitter' img={img4} link = 'https://twitter.com/kensho_finance'/>
                <ComponentOfEighthFrame darkMode={darkMode} text='Telegram' img={img5} link='https://t.me/+TdPlNOoBK8QzZGYy'/>
                <ComponentOfEighthFrame darkMode={darkMode} text='Chat' img={img6}/>
            </div>
            <ComponentOfEighthFrame darkMode={darkMode} text='FAQ' img={img7} link='https://kensho-finance.gitbook.io/kensho-docs/frequently-asked-questions'/>
            <Discord/>
            <div className={`landing-eighth-frame${darkMode ? '-dark' : ''}-container2`}>
                <div className={`landing-third-frame${darkMode ? '-dark' : ''}-main-text`}>
                    <div className={`landing-third-frame${darkMode ? '-dark' : ''}-main-text-black`} style={{
                        marginRight: '10px'
                    }}>Our</div>
                    {/* <div className='landing-third-frame-main-text-gradient' */}
                    <div className={`landing-third-frame${darkMode ? '-dark' : ''}-main-text-gradient`}
                    >partners</div>
                </div>
                <div className={`landing-eighth-frame${darkMode ? '-dark' : ''}-flex2`}>
                    
                    <a href="https://thorchain.org/" target="_blank" rel="noopener noreferrer">
                    
                    <img   style={{maxWidth: "350px", height: "80px"} } src={darkMode ? thorchain : thorchainwh } alt="ThorChain"/>
                    
                    </a>
                        
                    
                    <a href="https://www.mayaprotocol.com/" target="_blank" rel="noopener noreferrer">
                    <img  style={{maxWidth: "350px", height: "80px"}}  src={darkMode ? magpie : magpiewh} alt="Maya"/>

                    
                    </a>
                    
                  
                    <a href="https://nu.fi/" target="_blank" rel="noopener noreferrer">
                    <img style={{maxWidth: "350px", height: "80px"}}  src={darkMode ? nufi : nufiwh } alt="NUFI"/>
                
                    </a>
                 
                    
                    
                    <a href="https://li.fi/" target="_blank" rel="noopener noreferrer">
                    <img style={{maxWidth: "350px", height: "80px"}}  src={darkMode ? lifi : lifwh } alt="LIFI"/>
                    </a>
                   
                   
                </div>
            </div>
        </div>
    );
};

export default EighthFrame;